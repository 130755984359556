import {shortcuts, settings, registerShortcut, shortcutPassesCondition, IShortcut} from './index';
import I18n from '@/modules/i18n';
import h from 'stringjsx';
import {addDelegatedEventListener} from '@/functions/dom';

const BUTTON_SELECTOR = `#show-shortcodes`;

export function init(): void {
  registerShortcut({
    triggerKey: `I`,
    onTrigger: toggleDialog,
    elementSelector: BUTTON_SELECTOR,
    descriptionHtml: () => (
      <div class="show-shortcuts-button"><i class="fa fa-info"></i> <span>{I18n.t(`list_all_shortcuts`)}</span></div>
    ),
  });

  addDelegatedEventListener(document.body, `click`, BUTTON_SELECTOR, toggleDialog);
}

function toggleDialog(): void {
  if (document.querySelector(`#shortcuts-dialog-holder`)) {
    closeDialog();
  } else {
    openDialog();
  }
}

function openDialog() {
  const holder = document.createElement(`div`);
  holder.setAttribute(`id`, `shortcuts-dialog-holder`);
  holder.innerHTML = render();
  document.body.appendChild(holder);
  bindCloseEvent();
}

function render(): string {
  return (
    <div id="shortcuts_dialog">
      <div id="overlay" class="shortcuts-overlay"></div>
      <div id="js-shortcuts" class="shortcuts-dialog">
        <h4>{I18n.t(`shortcuts.all_available`)}</h4>
        <i id="close-shortcuts" class="fa fa-times"></i>
        <div id="shortcuts_custom_buttons">
          {renderButtons()}
          {renderPunchingLegend()}
        </div>
      </div>
    </div>
  );
}

function renderButtons(): string[] {
  return shortcuts
    .filter(shortcutPassesCondition)
    .map(renderShortcutKey);
}

function renderShortcutKey(shortcut: IShortcut): string {
  // dangerouslySetInnerHTML allows you to use string instead of JSX
  // https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
  return (
    <div class="flex m-b-2">
      <div class="flex-c-6 text-r p-r-2" dangerouslySetInnerHTML={{__html: shortcut.descriptionHtml()}}></div>
      <div class="flex-c-6"> - <code>Alt + Shift + {shortcut.triggerKey}</code></div>
    </div>
  );
}

function renderPunchingLegend(): string {
  if (!settings.includeLegendInInfo) return ``;

  return (
    <div class="clearfix">
      <hr />
      <div class="flex m-b-2">
        <div class="flex-c-6 text-r p-r-2">
          <div class="color-info focus"></div>
        </div>
        <div class="flex-c-6">{I18n.t(`shortcuts.focus`)}</div>
      </div>

      <div class="flex m-b-2">
        <div class="flex-c-6 text-r p-r-2"><div class="color-info warning"></div></div>
        <div class="flex-c-6">{I18n.t(`shortcuts.warning`)}</div>
      </div>

      <div class="flex m-b-2">
        <div class="flex-c-6 text-r p-r-2"><div class="color-info error"></div></div>
        <div class="flex-c-6">{I18n.t(`shortcuts.error`)}</div>
      </div>
    </div>
  );
}

function keydownHandler(event: Event): void {
  const KEY = (event as KeyboardEvent).which;
  const ESC = 27;

  if (KEY == ESC) {
    closeDialog();
  }
}

function bindCloseEvent(): void {
  window.addEventListener(`keydown`, keydownHandler);
  document.querySelector(`#shortcuts_dialog #close-shortcuts`)!.addEventListener(`click`, closeDialog);
}

function unbindCloseEvent(): void {
  window.removeEventListener(`keydown`, keydownHandler);

  // We do not need to unbind from button,
  // because it probably doesn't exist at this point,
  // or is about to be removed from DOM.
}

function closeDialog(): void {
  const element = document.querySelector(`#shortcuts-dialog-holder`)!;
  element.parentNode!.removeChild(element);
  unbindCloseEvent();
}
