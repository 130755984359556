/**
 * Function to be used for safely rendering arbitrary strings,
 * while creating HTML through string concatenation.
 *
 * Example:
 * ```js
 * // User's input:
 * const string = '<b>BIG!</b>';
 * // Bad:
 * const html = `<p>User wrote: "${string}"</p>`;
 * // Good:
 * const html = `<p>User wrote: "${escapeHtml(string)}"</p>`;
 * ```
 * Here you wouldn't want user's input to be viewed as HTML,
 * but processed, and rendered as text.
 */
export default function escapeHtml(unsafe: string): string {
  const p = document.createElement(`p`);
  p.textContent = unsafe;
  return p.innerHTML;
}
