import I18n from '@/modules/i18n';
import {ohNo} from '@/modules/oh_no';
import {BankAccountNumber} from '../bankAccountNumber';
import {Mod11} from "@/modules/checksum";
import {nonAccountNumberCharacters} from './cleaner';
import {convertCountryIdToCode} from './country';
import {validateIBANCheckDigits} from './iban';

export type IncomingParserOptions = {
  countryCode?: string | null;
  countryId?: number | null;
  iban?: boolean | null;
  bban?: boolean | null;
}

type ParserOptions = {
  countryCode: string | null;
  ibanEnabled: boolean;
  bbanEnabled: boolean;
}

export type ParsingErrorCode =
  `unsupported_type` |
  `wrong_length_for_country` |
  `NO_bban_mod11_fail` |
  `NO_iban_mod11_fail` |
  `iban_check_digit_fail` |
  `illegal_characters` |
  `NO_letters_in_the_wrong_place` |
  `cant_be_iban` |
  `cant_be_bban` |
  `too_long`
;

export type ParseResult = BankAccountNumber | ParsingError;

export function parse(subject: unknown, rawOptions?: IncomingParserOptions): ParseResult {
  const options = parseParserOptions(rawOptions);

  let result;
  if (typeof subject === `string`) {
    result = parseString(subject, options);
  } else {
    result = new ParsingError(`unsupported_type`);
  }

  return result;
}

function parseParserOptions(options?: IncomingParserOptions): ParserOptions {
  const newOptions: ParserOptions = {
    countryCode: null,
    ibanEnabled: true,
    bbanEnabled: true,
  };

  if (options?.countryCode) {
    newOptions.countryCode = options.countryCode;
  } else if (options?.countryId) {
    newOptions.countryCode = convertCountryIdToCode(options.countryId);
  }

  if (options?.iban === false) {
    newOptions.ibanEnabled = false;
  }

  if (options?.bban === false) {
    newOptions.bbanEnabled = false;
  }

  if (!newOptions.ibanEnabled && !newOptions.bbanEnabled) {
    const msg = `developer! dont disable both iban and bban`;
    ohNo(msg);
    throw msg;
  }

  return newOptions;
}

// ZTL has a validation that serbian IBAN should be 22 chars:
// developer_text: IBAN with country code RS should have a length of 22
// We could add RS: [18] to this map, but Baxxx told me serbian accounts numbers
// can have leading zeroes and that those leading zeroes can be elided which
// means the length varies.
// Example: 000005380001336079 & 5380001336079 are the same bban (in serbia)
const countryLengths: {[key: string]: number[]} = {
  NO: [11],
};
const MAX_LENGTH = 34;

function parseString(subject: string, options: ParserOptions): ParseResult {
  if (subject.match(nonAccountNumberCharacters)) {
    return new ParsingError(`illegal_characters`);
  }

  const accountNumber = new BankAccountNumber(subject, options.countryCode || undefined);
  const countryCode = accountNumber.countryCode;
  const isIban = accountNumber.isIban;
  const isBban = accountNumber.isBban;

  if (isIban && !options.ibanEnabled) {
    return new ParsingError(`cant_be_iban`);
  }
  if (isBban && !options.bbanEnabled) {
    return new ParsingError(`cant_be_bban`);
  }

  const normalized = accountNumber.normalized;
  if (normalized.length > MAX_LENGTH) {
    return new ParsingError(`too_long`);
  }

  if (isIban) {
    if (!validateIBANCheckDigits(normalized)) {
      return new ParsingError(`iban_check_digit_fail`);
    }
  }

  if (countryCode) {
    let countryLength = countryLengths[countryCode];
    const subjectLength = normalized.length;

    if (countryLength) {
      if (isIban) {
        countryLength = countryLength.map(l => l + 4);
      }
      if (!countryLength.some(length => length === subjectLength)) {
        return new ParsingError(
          `wrong_length_for_country`, {
            allowedLengths: countryLength,
            providedLength: subjectLength,
          }
        );
      }
    }

    if (countryCode === `NO`) {
      // Only the first two characters are allowed to be letters, everything else
      // should be a digit.
      if (normalized.match(/^.{2}.*[A-Z]+.*$/gm)) {
        return new ParsingError(`NO_letters_in_the_wrong_place`);
      }

      if (isBban) {
        if (!Mod11.isValid(normalized)) {
          return new ParsingError(`NO_bban_mod11_fail`);
        }
      } else {
        // eslint-disable-next-line
        if (!Mod11.isValid(accountNumber.bbanPartOfIban)) {
          return new ParsingError(`NO_iban_mod11_fail`);
        }
      }
    }
  }

  return accountNumber;
}

type ErrorInfo = {
  allowedLengths: number[],
  providedLength: number,
}

export class ParsingError {
  isError: true = true;

  code: ParsingErrorCode;
  info?: ErrorInfo;

  constructor(code: ParsingErrorCode, info?: ErrorInfo) {
    this.code = code;
    this.info = info;
  }

  localize(): string {
    return localizeError(this);
  }
}

function localizeError(error: ParsingError): string {
  // Using a manual mapping for greppability.
  // Another advantage is that the codes don't *have* to be the same.
  switch (error.code) {
    case `illegal_characters`:
      return I18n.t(`bank_account_number.errors.illegal_characters`);
    case `wrong_length_for_country`:
      return I18n.t(
        `bank_account_number.errors.wrong_length_for_country`,
        {
          allowed: error.info!.allowedLengths,
          provided: error.info!.providedLength,
        }
      );
    case `NO_letters_in_the_wrong_place`:
      return I18n.t(`bank_account_number.errors.NO_letters_in_the_wrong_place`);
    case `cant_be_iban`:
      return I18n.t(`bank_account_number.errors.cant_be_iban`);
    case `cant_be_bban`:
      return I18n.t(`bank_account_number.errors.cant_be_bban`);
    case `iban_check_digit_fail`:
      return I18n.t(`bank_account_number.errors.iban_check_digit_fail`);
    case `NO_bban_mod11_fail`:
    case `NO_iban_mod11_fail`:
      return I18n.t(`bank_account_number.errors.NO_mod11_fail`);
    case `too_long`:
      return I18n.t(`bank_account_number.errors.too_long`, {max: MAX_LENGTH});
    default:
      ohNo(`BankAccountNumber ${error.code} in localizeError`);
      return I18n.t(
        `bank_account_number.errors.generic_error`, {code: error.code},
      );
  }
}
