export function convertCountryIdToCode(id: number | null): string | null {
  switch (id) {
    case null:
      return null;
    case 161:
      return `NO`;
    case 245:
      return `RS`;
    default:
      return null;
  }
}
