import h from 'stringjsx';
import I18n from '@/modules/i18n';

export interface IColumn<Item> {
  show?: boolean;
  name?: string;
  className?: string | ((item: Item) => string);
  content?: string | ((item: Item) => string);
  title?: string | ((item: Item) => string) | `SAME_AS_CONTENT` | false;
  beforeRender?: (item: Item) => unknown;
  filterable?: {
    enabled: boolean;
    filterName?: string;
    default?: string;
    filterOptions?: { text: string, value: string }[];
    filterType?: `select` | `input`;
  };
  sortable?: {
    enabled: boolean;
    sortName?: string;
  },
  header?: {
    className?: string | ((column: IColumn<Item>) => string);
    title?: string | ((column: IColumn<Item>) => string);
    text?: string | ((column: IColumn<Item>) => string);
  };
}

function stringOrCall(thing: string | Function | undefined, ...args: unknown[]): string {
  if (typeof thing === `function`) {
    return thing(...args) || ``;
  } else {
    return thing || ``;
  }
}

export function renderTerrorCell<Item>(column: IColumn<Item>, item: Item): string {
  if (typeof column.beforeRender === `function`) column.beforeRender(item);
  const content = (typeof column.content === `function` ? column.content(item) : (item as any)[column.name!]) || ``;

  return (
    <td
      title={stringOrCall(column.title || ``, item)}
      class={stringOrCall(column.className, item)}
      dangerouslySetInnerHTML={{__html: content}}
    />
  );
}

export function renderTerrorSortableHeader<Item>(column: IColumn<Item>): string {
  function content(): string {
    const innerContent = stringOrCall(column.header?.text, column);
    if (!(column.sortable?.enabled)) return innerContent;

    return (
      <a
        href="#"
        onclick="return false;"
        data-sort={column.sortable.sortName}
        js-tt-sort-link
      >
        <i class="fa no-print fa-sort p-r-1" />
        {innerContent}
      </a>
    );
  }

  return <th
    title={stringOrCall(column.header?.title, column)}
    class={stringOrCall(column.header?.className, column)}
    dangerouslySetInnerHTML={{__html: content()}}
  />;
}

export function renderTerrorFilterableHeader<Item>(column: IColumn<Item>, filterValue: string): string {
  function content(): string {
    function selectOptions(): string[] {
      if (!column.filterable?.filterOptions) return [``];
      return column.filterable.filterOptions.map(option => {
        const selected = filterValue === option.value;
        return <option value={option.value} selected={selected}>{option.text}</option>;
      });
    }

    if (!column.filterable?.enabled) return ``;
    if (column.filterable.filterType === `select`) {
      return (
        <select data-filter-name={column.filterable.filterName}>
          {selectOptions()}
        </select>
      );
    } else {
      return <input
        type="text"
        class="placeholder-opacity-30"
        data-filter-name={column.filterable.filterName}
        placeholder={`🔎 ${I18n.t(`search`)}`}
        value={filterValue}
      />;
    }
  }

  return (
    <th
      title={stringOrCall(column.header?.title, column)}
      class={stringOrCall(column.header?.className, column)}
    >
      {content()}
    </th>
  );
}

