export function startLoadingSpinner(button: HTMLButtonElement) {
  button.disabled = true;
  if (button.parentElement) {
    button.parentElement.classList.add(`cursor-progress`);
  }

  const spinner = document.createElement(`i`);
  spinner.classList.add(`fa`);
  spinner.classList.add(`fa-spinner`);
  spinner.classList.add(`fa-pulse`);
  spinner.classList.add(`m-r-1`);

  button.insertAdjacentElement(`afterbegin`, spinner);

  return () => {
    spinner.remove();
    button.disabled = false;
    if (button.parentElement) {
      button.parentElement.classList.remove(`cursor-progress`);
    }
  };
}

import {shakeToDrawAttention} from '@/modules/element_animator';

type TimeoutID = ReturnType<typeof setTimeout>;
let revealYouHaveValidationErrorsTimeoutId: TimeoutID | null = null;

export function revealYouHaveValidationErrors(msgElement: Element) {
  if (revealYouHaveValidationErrorsTimeoutId) {
    clearTimeout(revealYouHaveValidationErrorsTimeoutId);
  }

  msgElement.classList.remove(`hidden`);

  // This first timeout is here to make the ux feel just a little bit better.
  // Experimentation revealed that it didn't feel quite right unless the text
  // appears a few milliseconds before it starts shaking.
  setTimeout(() => {
    shakeToDrawAttention({element: msgElement as HTMLElement});

    revealYouHaveValidationErrorsTimeoutId = setTimeout(() => {
      msgElement.classList.add(`hidden`);
    }, 2500);
  }, 79);
}
