import {ohNo} from '@/modules/oh_no';
import {
  parse as parseAccountNumber,
  IncomingParserOptions,
} from './bankAccountNumber/parser';
import {
  clean as cleanAccountNumber,
  nonAccountNumberCharacters,
} from './bankAccountNumber/cleaner';
import {guessCountryCodeFromBbanLength} from './bankAccountNumber/guesser';
import {convertCountryIdToCode} from './bankAccountNumber/country';
import {bbanToIban} from './bankAccountNumber/iban';

export class BankAccountNumber {
  public isError: false = false;
  public countryCode?: string;

  private number: string;

  constructor(
    number?: number | string | null,
    countryCode?: string,
  ) {
    if (number === null || number === undefined) number = ``;
    this.number = number.toString();

    this.countryCode = countryCode;

    if (this.isBban) {
      const guess = guessCountryCodeFromBbanLength(this.normalized);
      if (guess) { this.countryCode ||= guess; }
    } else if (this.isIban) {
      this.countryCode = this.countryCodeFromIban;
    }
  }

  static fmt(
    number: number | string | null,
    options?: FormattingOptions,
  ): string {
    const n = this.parse(number, {countryCode: options?.country_code});
    if (n.isError) {
      if (number === null) return ``;
      return typeof number === `string` ? number : String(number);
    }
    return n.formatted(options);
  }

  static parse(subject: unknown, options?: IncomingParserOptions) {
    return parseAccountNumber(subject, options);
  }

  // Cleaning, as opposed to normalization, is suitable for use on input fields.
  // It is commonly used to prevent validation errors (so the user doesn't have)
  // to deal with them themselves.
  static clean(subject: string) {
    return cleanAccountNumber(subject);
  }

  static isIban(subject: string) {
    return new BankAccountNumber(subject).isIban;
  }

  static isBban(subject: string) {
    return new BankAccountNumber(subject).isBban;
  }

  formatted(options: FormattingOptions = {}): string {
    if (!this.number) {
      return this.number;
    }

    if (this.isIban) {
      return this.formatIban(this.normalized);
    } else {
      let country_code = options.country_code || null;
      country_code ||= convertCountryIdToCode(options.country_id || null);
      country_code ||= this.countryCode || null;
      return this.formatBban(this.normalized, country_code);
    }
  }

  get isIban(): boolean {
    const searchPattern = new RegExp(/^[A-Z]{2}[0-9]{2}/);
    return searchPattern.test(this.normalized);
  }

  get isBban(): boolean {
    return !this.isIban;
  }

  get countryCodeFromIban(): string {
    if (!this.isIban) {
      throw `not an iban number: "${this.number}"`;
    }

    return this.normalized.slice(0, 2);
  }

  get bbanPartOfIban(): string {
    if (!this.isIban) {
      throw `not an iban number: "${this.number}"`;
    }

    return this.normalized.slice(4);
  }

  // Normalizing is suitable for submitting data to the backend, or otherwise
  // processing the data. (such as checking length). It would be annoying
  // if you ran it on the input field itself, however, since it removes
  // formatting which is commonly used to make numbers more readable.
  get normalized(): string {
    return this.number
      .replace(nonAccountNumberCharacters, ``)
      .replace(/[\s.,:-]+/g, ``)
      .toUpperCase();
  }

  toIban(): string | null {
    if (this.isIban) {
      ohNo(`tried to call toIban on an iban`);
      return null;
    } else {
      // eslint-disable-next-line
      if (this.countryCode) {
        return bbanToIban(this.normalized, this.countryCode);
      } else {
        return null;
      }
    }
  }

  equals(other: BankAccountNumber): boolean {
    return this.normalized == other.normalized;
  }

  private formatIban(iban: string): string {
    return `${iban.slice(0, 4)} ${this.formatBban(
      this.bbanPartOfIban,
      this.countryCodeFromIban,
    )}`;
  }

  private formatBban(number: string, country_code: string | null): string {
    if (country_code == `NO`) {
      return `${number.slice(0, 4)} ${number.slice(4, 6)} ${number.slice(6)}`;
    } else if (country_code == `RS`) {
      let copy = number.slice(0,3);
      if (number.length >= 3) copy += `-`;
      if (number.length >= 6) {
        copy += `${number.slice(3, -2)}-${number.slice(-2)}`;
      } else {
        copy += number.slice(3);
      }
      return copy;
    }
    return number;
  }
}

type FormattingOptions = {
  country_code?: string;
  country_id?: number;
};

export default BankAccountNumber;
export type BankAccountNumberParserOptions = IncomingParserOptions;
