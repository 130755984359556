export default function buildAlertifyLoggedOut() {
  if (!window.alertify.LoggedOut) {
    window.alertify.dialog(`LoggedOut`, function factory() {
      return {
        main: function(message) {
          this.message = message;
        },
        setup: function() {
          return {
            buttons: [
              {
                text: I18n.t(`users.sign_in`),
                className: `ajs-use`
              },
              {
                text: I18n.t(`cancel`),
                className: `ajs-cancel`,
                key: 27 /*Esc*/
              },
            ],
            options: {
              title: I18n.t(`users.logged_out`),
              movable: false,
              resizable: false,
              closable: false,
              maximizable: false,
              modal: true,
            }
          };
        },
        prepare: function() {
          this.setContent(this.message);
        },
        callback: function(close_event) {
          if (close_event.button.className == `ajs-use`) {
            window.location.reload();
          }
        }
      };
    }, true);
  }
}
