// When you want to use shortcuts, make sure to use window.shortcuts
// instead of importing the shortcuts as a module. This is because
// `allShortcuts` variable is going to be instantiated twice,
// and there would be no connection between shortcuts
// registered in ESM and shortcuts registered in CJS.

import {ohNo} from '@/modules/oh_no';
import {init as initStyles} from "./styles";
import {init as initIndicators} from "./indicators";
import {init as initInfoPopup} from "./infoPopup";

export interface IShortcut {
  triggerKey: string;
  key: number;
  condition?: (() => boolean) | boolean;
  onTrigger: () => unknown;
  descriptionHtml: () => string;
  elementSelector?: string;
  blurActiveElement?: boolean;
}

interface ISettings {
  underlineShortcuts: boolean;
  includeLegendInInfo: boolean;
}

const allShortcuts: IShortcut[] = [];
export {allShortcuts as shortcuts};

export const settings: ISettings = {
  underlineShortcuts: false,
  includeLegendInInfo: false,
};

export function start(): void {
  bindWindowEvent();
  initStyles();
  initIndicators();
  initInfoPopup();
}

type MergeStrategy = `replace` | `ignore`;

type RegisterOptions = {
  mergeStrategy?: MergeStrategy;
}

export function registerShortcuts(
  shortcuts: Omit<IShortcut, `key`>[],
  options: RegisterOptions = {},
): void {
  shortcuts.forEach(shortcut => registerShortcut(shortcut, options));
}

export function registerShortcut(
  shortcut: Omit<IShortcut, `key`>,
  options: RegisterOptions = {},
): void {
  let key = shortcut.triggerKey.charCodeAt(0);
  if (shortcut.triggerKey === `PageUp`) key = 33;
  if (shortcut.triggerKey === `PageDown`) key = 34;
  if (shortcut.triggerKey === `End`) key = 35;
  if (shortcut.triggerKey === `Home`) key = 36;

  const existingShortcut = allShortcuts.find(s => s.triggerKey === shortcut.triggerKey);

  if (existingShortcut) {
    if (options.mergeStrategy === `replace`) {
      allShortcuts.splice(
        allShortcuts.indexOf(existingShortcut),
        1,
        {...shortcut, key},
      );
      // TODO: Cleanup styles, as the new shortcut might have a
      //       different selector than the replaced one.
    } else if (options.mergeStrategy === `ignore`) {
      // Well... ignore it.
    } else {
      ohNo(
        `Shortcuts: your shortcut for \`${shortcut.triggerKey}\` is being overwritten, ` +
        `you specified merge strategy: ${options.mergeStrategy}, which is not supported!` +
        `New shortcut will be ignored.`
      );
    }
  } else {
    allShortcuts.push({...shortcut, key});
  }

  window.dispatchEvent(new CustomEvent(`Shortcuts.shortcutRegistered`));
}

export function shortcutPassesCondition(shortcut: IShortcut): boolean {
  if (typeof shortcut.condition == `undefined`) return true;
  if (typeof shortcut.condition == `function`) return shortcut.condition();
  if (typeof shortcut.condition == `boolean`) return shortcut.condition;

  throw `Wrong type of shortcut.condition for shortcut \`${shortcut.triggerKey}\`.`;
}

function bindWindowEvent(): void {
  window.addEventListener(`keydown`, event => {
    const KEY = event.keyCode;
    const ALT = event.altKey;
    const SHIFT = event.shiftKey;

    if (!ALT || !SHIFT) return;

    allShortcuts.forEach(shortcut => {
      if (shortcut.key === KEY) {
        if (!shortcutPassesCondition(shortcut)) return;

        event.preventDefault();
        if (shortcut.blurActiveElement && document.activeElement) {
          (document.activeElement as HTMLElement).blur();
        }
        shortcut.onTrigger();
      }
    });
  });
}
