import {shortcuts, settings} from './index';

export function init(): void {
  new MutationObserver(addStylesToElements)
    .observe(document.body, {
      childList: true, // observe direct children
      subtree: true, // and lower descendants too
    });

  window.addEventListener(`Shortcuts.shortcutRegistered`, addStylesToElements);
}

function addStylesToElements() {
  shortcuts
    .forEach(shortcut => {
      if (!shortcut.elementSelector) return;

      document
        .querySelectorAll(shortcut.elementSelector)
        .forEach(element => {
          element.classList.add(`has-shortcut`);
          if (settings.underlineShortcuts) {
            element.classList.add(`always-has-shortcut`);
          }
        });
    });
}
