import {IColumn} from './terror_column';
import BaseTerrorTable, {ISort, ITableOptions} from './base_terror_table';
import {cloneDeep, escapeRegExp} from 'lodash';

function sortBy<Item>(data: Item[], sort: ISort): Item[] {
  return [...data].sort((a: any, b: any) => {
    if (sort.sord === `ASC`) return (a[sort.sidx] > b[sort.sidx]) ? 1 : -1;
    return a[sort.sidx] < b[sort.sidx] ? 1 : -1;
  });
}

function filterBy<Item>(data: Item[], filters: { [filterName: string]: string }): Item[] {
  return data.filter(item => {
    return Object.keys(filters).every(
      (propertyName) =>
        String(item[(propertyName as keyof Item)]).toLowerCase()
          .match(escapeRegExp(filters[propertyName].toLowerCase())));
  });
}

export default function LocalTerrorTable<Item>(
  tableOptions: Omit<ITableOptions<Item>, `rows` | `dataUrl`>,
  tableColumns: IColumn<Item>[],
  tableData: Item[]
) {
  const baseOptions = {...tableOptions};
  baseOptions.filterMethod = `onInputDebounced`;
  const baseTerrorTable = BaseTerrorTable(baseOptions, tableColumns);
  let fullData: Item[] = cloneDeep(tableData);

  function filteredData(): Array<Item> {
    return sortBy(
      filterBy(fullData, baseTerrorTable.filters),
      baseTerrorTable.sort,
    );
  }

  function initialRender(): void {
    baseTerrorTable.dataAPI.setRows(filteredData());
    baseTerrorTable.initialRender();
    baseTerrorTable.htmlRef().on(`sortChanged filtersChanged`, filterAndSort);
    baseTerrorTable.htmlRef().on(`pageChanged`, pageChanged);
    if (baseOptions.pagination?.enabled) baseTerrorTable.setNumberOfPages();
  }

  function pageChanged(): void {
    baseTerrorTable.reRenderBody();
  }

  // TODO: optimize when changing sort, we don't need to re-filter
  function filterAndSort(): void {
    // TODO: This current structure makes us unable to persist the $_selected between sorts/filters, consider improving.
    setRows(filteredData());
    if (baseOptions.pagination?.enabled) {
      baseTerrorTable.setPage(1);
      baseTerrorTable.setNumberOfPages();
    }
  }

  function setRows(data: Array<Item>): void {
    baseTerrorTable.dataAPI.setRows(data);
    baseTerrorTable.reRenderBody();
  }

  function removeRows(filter: (item: Item) => boolean): void {
    fullData = fullData.filter(item => !filter(item));
    baseTerrorTable.dataAPI.removeRows(filter);
    baseTerrorTable.reRenderBody();
  }

  function appendRows(data: Array<Item>): void {
    if (!data.length) return;

    fullData.push(...cloneDeep(data));
    setRows(filteredData());
  }

  return {
    htmlRef: baseTerrorTable.htmlRef,
    initialRender,
    dataAPI: {
      setRows,
      removeRows,
      appendRows,
    },
  };
}
