import {shortcuts, shortcutPassesCondition} from './index';
import h from 'stringjsx';

export function init(): void {
  window.addEventListener(`keydown`, event => {
    const SHIFT = 16;
    const ALT = 18;
    if (event.shiftKey && event.altKey && (event.keyCode == SHIFT || event.keyCode == ALT)) {
      showIndicators();
    }
  });

  window.addEventListener(`keyup`, event => {
    if (event.shiftKey || event.altKey) {
      hideIndicators();
    }
  });

  window.addEventListener(`blur`, event => {
    hideIndicators();
  });
}

function showIndicators(): void {
  shortcuts
    .forEach(shortcut => {
      if (!shortcut.elementSelector) return;
      if (!shortcutPassesCondition(shortcut)) return;

      document
        .querySelectorAll(shortcut.elementSelector)
        .forEach(element => {
          const indicator = document.createElement(`div`);
          indicator.setAttribute(`js-shortcut-button-indicator`, `true`);
          indicator.innerHTML = (
            <div class="shortcut-button-indicator">
              {shortcut.triggerKey}
            </div>
          );

          element.appendChild(indicator);
        });
    });
}

function hideIndicators(): void {
  document.querySelectorAll(`[js-shortcut-button-indicator]`)
    .forEach(element => element.parentNode!.removeChild(element));
}
