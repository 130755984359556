type CallbackFunction = (obj: Traversable, key: string, value: unknown) => void;

type Traversable = {[key: string]: Traversable | unknown};

export default function traverse(
  obj: Traversable,
  callback: CallbackFunction,
): void {
  if (!isTraversable(obj)) throw `ArgumentError: Cannot traverse through ${typeof obj} ${String(obj)}`;

  for (const key in obj) {
    const child = obj[key];

    if (isTraversable(child)) {
      traverse(child, callback);
    } else {
      callback(obj, key, child);
    }
  }
}

function isTraversable(thing: Traversable | unknown): thing is Traversable {
  if (thing && typeof thing === `object`) return true;

  return false;
}
