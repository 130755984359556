import {IColumn} from "./terror_column";

export default function TerrorColumnBuilder<Item>(defaultColumnSettings: () => IColumn<Item>) {

  const defaultColumn: () => IColumn<Item> = defaultColumnSettings;
  const columns: IColumn<Item>[] = [];

  function registerColumn(options: IColumn<Item>) {
    const defaults = defaultColumn();
    const column = {
      ...defaults,
      ...options,
      ...{header: {...defaults.header, ...options.header}},
      ...{filterable: {enabled: false, ...defaults.filterable, ...options.filterable}},
      ...{sortable: {enabled: false, ...defaults.sortable, ...options.sortable}},
    };

    if (column.content === undefined) {
      column.content = (item: Item) => column.name ? (item as any)[column.name] || `` : ``;
    }

    if (column.title === undefined || column.title === `SAME_AS_CONTENT`) {
      column.title = (item: Item) => (typeof column.content == `function` ? column.content(item) : column.content) || ``;
    }

    if (!column.filterable) {
      column.filterable = {
        enabled: false,
      };
    }

    if (column.filterable!.enabled && !column.filterable!.filterName) {
      column.filterable.filterName = column.name;
    }

    if (column.filterable!.filterName && columns.some(c => c.filterable?.filterName === column.filterable!.filterName)) {
      throw new Error(`You are setting multiple columns with same filterName: "${column.filterable.filterName}". Please set only one filter per column.`);
    }

    if (!column.sortable) {
      column.sortable = {
        enabled: false,
      };
    }

    if (!column.sortable.sortName) {
      column.sortable.sortName = column.name;
    }

    if (!column.header) {
      column.header = {};
    }

    if (column.header.className === undefined) {
      if (typeof column.className === `function`) {
        // eslint-disable-next-line no-console
        console.warn(`column.className is a function which requires argument of type Item which is not available for the header. Please provide column.className as a string or add column.header.className.`);
      } else {
        column.header.className = column.className || ``;
      }
    }

    if (column.header.text === undefined) {
      column.header.text = ``;
    }

    if (column.header.title === undefined) {
      column.header.title = column.header.text;
    }

    columns.push(column);
  }

  return {
    columns,
    registerColumn,
  };
}
