export function getItem(key: string) {
  return localStorage.getItem(key);
}

export function setItem(key: string, value: string) {
  return localStorage.setItem(key, value);
}

export function removeItem(key: string) {
  return localStorage.removeItem(key);
}

export function setWithExpiry(key: string, value: object, ttl: number | Date = 60 * 60): void {
  let expiry;
  if (typeof ttl === `number`) {
    const now = new Date();
    expiry = now.getTime() + (ttl * 1000);
  }
  else if (typeof ttl === `object`) {
    expiry = ttl.getTime();
  }

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: expiry,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string): object | null {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
