// Completely based on Snotr server checksum.rb

class Checksum {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isValid(value: string | number, options?: any): boolean {
    const providedCheckDigit = value.toString().split(``).slice(-1).join(``);
    const numberToValidate = value.toString().split(``).slice(0, -1).join(``);
    const calculatedCheckDigit = this.calculate(numberToValidate, options);

    return providedCheckDigit === calculatedCheckDigit;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  public static calculate(value: string | number, options: any): string {
    return `Not implemented!`;
  }
}

export class Mod10 extends Checksum {
  public static calculate(value: string | number): string {
    const digits = value.toString().split(``).map(Number);
    let toggle = 1;

    const sum = digits.reverse().reduce((s, digit) => {
      toggle = toggle ? 0 : 1; // toggle = toggle XOR 1;
      const val = digit * (2 - toggle);
      return s + val.toString().split(``).map(Number).reduce((digSum, dig) => digSum + dig);
    }, 0);

    const digit = 10 - (sum % 10);
    return digit === 10 ? `0` : digit.toString();
  }
}

export class Mod11 extends Checksum {
  public static weights = [2, 3, 4, 5, 6, 7];
  public static ssn10thDigitWeights = [2, 5, 4, 9, 8, 1, 6, 7, 3];

  // Override Checksum.isValid to get proper declaration of params.
  // We must bind(this) to get correct class when calling .calculate().
  public static isValid(value: string | number, weights: number[] = this.weights): boolean {
    return Checksum.isValid.bind(this)(value, weights);
  }

  public static calculate(value: string | number, weights: number[] = this.weights): string {
    let weight = 0;

    const digits = value.toString().split(``).map(Number);

    const sum = digits.reverse().reduce((s, digit) => {
      return s + digit * weights[weight++ % weights.length];
    }, 0);

    const remainder = sum % 11;

    if (remainder === 0) return `0`;
    if (remainder === 1) return `-`;
    return (11 - remainder).toString();
  }
}
