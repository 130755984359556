// This file should be loaded with esbuild_script_tag, type: :plain,
// or, as CJS file. This is because scripts imported here are
// meant to be used within asset pipeline scripts.
// This is why this file should be loaded synchronously,
// and before those files.

import {onDocumentReady} from '@/functions/dom';
import {escape, debounce} from 'lodash';
import currencyFormatToNumber from '@/functions/processors/currencyFormatToNumber';
import sortByTermRelevance from '@/functions/processors/sortByTermRelevance';
import * as ourStorage from '@/modules/localStorage';
import escapeHtml from '@/functions/processors/escapeHtml';
import traverse from '@/functions/processors/traverse';
import BankAccountNumber from '@/modules/bankAccountNumber';
import {ohNo, alertifySomethingWentWrongReported} from '@/modules/oh_no';
import {TerrorColumnBuilder, LocalTerrorTable, RemoteTerrorTable} from "@/modules/terror_table";
import {getActionLog} from '@/components/action_log';
import {configureAjax, monkeyPatchXHRWithCurrentUnitHeader} from '@/modules/request/ajax_configuration';
import {loadRequirements} from '@/modules/request/error_handlers';
import {registerShortcut, registerShortcuts, settings, start as startShortucts} from "@/modules/shortcuts/index";
import {hydrateDatepickers} from '@/components/datepicker';
import BabbleID from '@/modules/babble_id';
import AlertifyJS from 'alertifyjs';
import {startLoadingSpinner} from '@/modules/buttons';

window.alertify = AlertifyJS;
window.initDatePicker = (rootNode = document, options = {}) => { hydrateDatepickers(rootNode, options); };
window.lodash_html_escape = escape;
window.lodash_debounce = debounce;
window.currencyFormatToNumber = currencyFormatToNumber;
window.sortByTermRelevance = sortByTermRelevance;
window.formatBankAccount = (number, countryId) => BankAccountNumber.fmt(number, {country_id: countryId});
window.fakturabankLocalStorage = ourStorage;
window.escapeHtml = escapeHtml;
window.traverse = traverse;
window.ohNo = ohNo;
window.startLoadingSpinner = startLoadingSpinner;

window.TerrorColumnBuilder = TerrorColumnBuilder;
window.LocalTerrorTable = LocalTerrorTable;
window.RemoteTerrorTable = RemoteTerrorTable;

window.getActionLog = getActionLog;

window.shortcuts = {
  registerShortcut,
  registerShortcuts,
  settings,
};

window.BabbleID = BabbleID;

monkeyPatchXHRWithCurrentUnitHeader();
configureAjax();

onDocumentReady(() => {
  hydrateDatepickers();
  startShortucts();

  const metaElement = document.querySelector(`meta[name="always_mark_shortcuts"]`);
  const content = metaElement?.getAttribute(`content`);
  window.shortcuts.settings.underlineShortcuts = content === `true`;

  window.alertify.somethingWentWrongReported =
    alertifySomethingWentWrongReported;
  loadRequirements();
});
