import {ohNo} from '@/modules/oh_no';

function translate(key) {
  return I18n.t(`alertify_please_reload.${key}`);
}

export default function buildAlertifyPleaseReload() {
  if (!alertify.PleaseReload) {
    alertify.dialog(`PleaseReload`, function factory() {
      return {
        main: function(msg) {
          this.msg = msg;
        },
        setup: function() {
          return {
            buttons: [
              {
                code: `refresh_now`,
                text: translate(`refresh_now`),
                scope: `auxiliary`,
              },
              {
                code: `refresh_later`,
                text: translate(`refresh_later`),
                scope: `primary`,
              },
            ],
            options: {
              title: translate(`title`),
              closable: false,
              closableByDimmer: false,
              maximizable: false,
            }
          };
        },
        build: function() {
          this.elements.content.innerHTML = `
            <p>
              ${translate(`body`)}
            </p>
            <p class="pls-reload-msg">
              <!--- replace me ---!>
            </p>
            <p>
              ${translate(`if_you_see_me_again`)}
            </p>
          `;

          const refreshIcn = document.createElement(`i`);
          refreshIcn.className = `fa fa-fw fa-refresh`;
          this.elements.buttons
            .auxiliary
            .querySelector(`button`)
            .prepend(refreshIcn);
        },
        prepare: function() {
          const content = this.elements.content;
          // remove the scrollbar (at least in modern browsers)
          content.style.height = `fit-content`;

          const msgEl = content
            .querySelector(`.pls-reload-msg`);

          msgEl.textContent = this.msg;
          msgEl.style.color = `var(--fb-red-500)`;
        },
        callback: function(closeEvent) {
          const buttonCode = closeEvent.button.code;

          if (buttonCode === `refresh_later`) {
            alertify.warning(translate(`reminder`), 0);
          } else if (buttonCode === `refresh_now`) {
            window.location.reload();
          } else {
            ohNo(`UNKNOWN BUTTON:`, closeEvent.button);
          }
        },
      };
    });
  }
}
