// Use jQuery's datepicker. Options are documented here:
// https://api.jqueryui.com/datepicker/

export function hydrateDatepickers(
  rootNode: HTMLElement | Document = document,
  options?: JQuery.DatepickerOptions,
) {
  Array.from(
    rootNode.querySelectorAll<HTMLElement>(`.date`),
  ).forEach(dateElement => {
    // "svelte-owned" datepickers call hydrate on themselves directly.
    if (!dateElement.dataset.svelteOwned) {
      hydrate(dateElement, options);
    }
  });
}

const IMAGE_SETTINGS: JQuery.DatepickerOptions = {
  showOn: `both`,
  buttonImage: `/images/calendar.gif`,
  buttonImageOnly: true
};

const BASIC_SETTINGS: JQuery.DatepickerOptions = {
  showButtonPanel: true,
  dateFormat: `yy-mm-dd`,
  changeMonth: true,
  changeYear: true,
  onSelect: function(date, instance) {
    const inputElement = (this as HTMLInputElement);
    inputElement.dispatchEvent(new Event(`input`, {bubbles: true}));
    inputElement.dispatchEvent(new Event(`change`, {bubbles: true}));

    // JQuery datePicker triggers the change event with input.trigger("change").
    // This will trigger all event handlers added to the input using JQuery's
    // .on(`change`), but not events added with addEventListener or HTML
    // attribute onchange. this.dispatchEvent(new Event(`change`)); was added
    // so we can use the datepickers with vanilla javascript event handlers.
  }
};

export function hydrate(
  element: HTMLElement,
  options?: JQuery.DatepickerOptions,
) {
  const use_image = !$(element).hasClass(`no-calendar-image`);
  let settings = $.extend(true, {}, BASIC_SETTINGS, use_image ? IMAGE_SETTINGS : {});
  settings = $.extend(true, {}, settings, options);

  // some pages we set date class on non input elements e.g. charging report
  if ($(element).is(`input`)) {
    // wrap datepicker in div in order to group the input and calendar image for easier control
    if ($(element).parent(`.flex-datepicker-container`).length == 0) {
      $(element).wrap(`<div class="flex-datepicker-container"></div>`);
    }
    $(element).datepicker(settings);
    $(element).attr(`autocomplete`, `off`);
    $(element).on(`keydown`, function(event) {
      window.dateAddMinus(element, event);
    });
  }
}
