import modulo from "@/functions/math/modulo";

export function bbanToIban(bban: string, countryCode: string): string | null {
  switch (countryCode) {
    case `NO`:
      return generate_NO_IBAN(bban);
    default:
      return null;
  }
}

function generate_NO_IBAN(bban: string) {
  const paddedCheckDigits = generateIbanCheckDigits(bban, `NO`)
    .toString()
    .padStart(2, `0`);
  return `NO${paddedCheckDigits}${bban}`;
}

function generateIbanCheckDigits(bban: string, countryCode: string) {
  // Wikipedia[validating_iban] says there is a specific way to check if an IBAN
  // is valid, but it says 'If the remainder is 1, the check digit test is
  // passed and the IBAN might be valid.'. might, MIGHT! We don't want might,
  // but want yes or no. IBAN consists of two country code chars, two check
  // digits and BBAN. We can calculate IBAN from BBAN and country code. We can
  // also extract BBAN and country code from IBAN.

  // To generate the (generate the iban check digits)[generating-iban-check]
  // * Move the country code to the end
  // * remove the checksum from the beginning
  // * Add "00" to the end
  // * modulo 97 on the amount
  // * subtract remainder from 98, (98 - remainder)
  // * Add a leading 0 if the remainder is less then 10 (padStart(2, "0"))
  //   (we skip this in validation since we compare int, not string)
  //
  // [validating_iban][https://en.wikipedia.org/wiki/International_Bank_Account_Number#Validating_the_IBAN]
  // [generating-iban-check][https://en.wikipedia.org/wiki/International_Bank_Account_Number#Generating_IBAN_check_digits]

  const some_variable = replaceCharacterWithCode(`${bban}${countryCode}00`);
  const rest = modulo(some_variable, 97);
  return 98 - rest;
}

function replaceCharacterWithCode(str: string): string {
  // It is slower but alot more readable
  // https://jsbench.me/ttkzgsekae/1
  return str.split(``)
    .map(c => {
      const code = c.charCodeAt(0);
      return code >= 65 ? (code - 55).toString() : c;
    })
    .join(``);
}

export function validateIBANCheckDigits(iban: string) {
  const countryCode : string = iban.slice(0, 2);
  const providedCheckDigits : number = parseInt(iban.slice(2, 4), 10);
  const bban : string = iban.slice(4);

  const checkDigits = generateIbanCheckDigits(bban, countryCode);

  return checkDigits === providedCheckDigits;
}
