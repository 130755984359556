import h from 'stringjsx';
import I18n from '@/modules/i18n';
import {
  IColumn,
  renderTerrorSortableHeader,
  renderTerrorFilterableHeader,
  renderTerrorCell,
} from './terror_column';
import {cloneDeep, debounce} from 'lodash';
import PaginationComponent from '../pagination/pagination-component';

export interface ISort {
  sidx: string;
  sord: `ASC` | `DESC`;
}

export interface EventPayload {
  payload: any;
}

export interface TerrorTableEvent extends JQuery.TriggeredEvent {
  payload: any
}

export interface ITableOptions<Item> {
  id: string;
  className?: string;
  element: string | (() => string);
  expandable?: boolean;
  expandedTemplate?: string | ((item: Item) => string);
  showRowNumbers?: boolean;
  selectableRows?: boolean | ((item: Item) => true | string[]);
  selectableUID?: ((item: Item) => string);
  rowAttrs?: ((item: Item) => {[attribute: string]: string});
  rowClassName?: ((item: Item) => string) | string;
  defaultSortName?: string;
  defaultSortDirection?: `ASC` | `DESC`;
  filterMethod: `onInputDebounced` | `onEnter`;
  dataUrl?: string;
  rows?: number;
  pagination?: { enabled: false } | {
    enabled: true,
    totalRowsPath: string,
    dataGetterPath: string,
  },
  footer?: () => string,
}

export default function BaseTerrorTable<Item>(
  tableOptions: ITableOptions<Item>,
  tableColumns: IColumn<Item>[],
) {

  const TILDE_REPLACEMENT = `TILDECHARACTER`;

  let fullData: Item[] = [];
  const sort: ISort = {
    sidx: tableOptions.defaultSortName || `id`,
    sord: tableOptions.defaultSortDirection || `DESC`,
  };

  if (!tableOptions.element) throw new Error(`TerrorTable.options, option 'element' is missing`);
  const options: ITableOptions<Item> = {
    expandable: false,
    showRowNumbers: false,
    selectableRows: false,
    selectableUID: item => JSON.stringify(item),
    pagination: {enabled: false},
    ...tableOptions,
    defaultSortName: undefined,
    defaultSortDirection: undefined,
  };
  if (!tableOptions.id) throw new Error(`TerrorTable.options, option 'id' is missing, this id has to be unique on the page it is loaded`);

  function validateFilterNames(): void {
    const valid_name = /^([a-zA-Z0-9_-]+)$/;
    tableColumns.forEach(key => {
      if (key.filterable?.enabled === true) {
        if (!valid_name.test(`${key.filterable.filterName}`)) {
          throw new Error(`TerrorTable[${options.id}].tableColumns: ${key.header?.text},  filterName "${key?.filterable?.filterName}" is not valid. Name is only allowed to contain letters, numbers, dashes, and underscores.`);
        }
      }
    });
  }

  validateFilterNames();
  const columns: IColumn<Item>[] = tableColumns;
  const filters: { [filterName: string]: string } = {};
  let numberOfPages = 0;
  let page = 1;
  populateFilters();

  function htmlRef(): JQuery<HTMLElement> {
    return typeof options.element === `function` ? $(options.element()) : $(options.element);
  }

  function loaderRef(): JQuery<HTMLElement> {
    return htmlRef().find(`[js-tt-loader]`);
  }

  function filtersNodeSelector(): string {
    return `input:not(.tt-select-all-rows), select`;
  }

  function filtersNodes(): JQuery<HTMLElement> {
    return htmlRef().find(`thead`).find(filtersNodeSelector());
  }

  function emit(eventName: string, payload?: Object): void {
    htmlRef().trigger($.Event(eventName, {payload} as EventPayload));
  }

  function ready(): void {
    htmlRef().find(`thead`).on(`click`, `[js-tt-sort-link]`, e => setSort(e));
    htmlRef().find(`tbody`).on(`click`, `[js-expand]`, e => {
      const td = $(e.currentTarget);
      const currentlyExpanded = !!td.find(`i.fa-chevron-down`).length;
      setExpand(td, !currentlyExpanded);
    });
    htmlRef().find(`thead`).on(`click`, `[js-expand-all]`, e => {
      const currentlyExpanded = !!$(e.currentTarget).find(`i.fa-chevron-down`).length;
      setExpandAll(!currentlyExpanded);
    });
    bindFiltersChanged();
    htmlRef().find(`thead`).on(`input`, filtersNodeSelector(), setFilters);
    htmlRef().find(`tbody`).on(`click`, `.tt-select-row`, selectRowEvent);
    htmlRef().find(`thead`).on(`click`, `.tt-select-all-rows`, selectAllRowsEvent);
    updateSortIcon();
    if (Object.keys(filters).length > 0) filtersChanged(); // if filters are set from url or defaults, this has changed from being nothing.
  }

  // filtering stuff
  function setFilters(event: JQuery.TriggeredEvent): void {
    const el = event.currentTarget;
    const filterKey = $(el).attr(`data-filter-name`);
    const filterVal = $(el).val();
    if (filterKey) setFilter(filterKey, filterVal);
  }

  function bindFiltersChanged(): void {
    if (tableOptions.filterMethod === `onInputDebounced`) {
      htmlRef().find(`thead`).on(`input`, filtersNodeSelector(), debounce(filtersChanged, 250));
    } else if (tableOptions.filterMethod === `onEnter`) {
      const handler = (event: JQueryKeyEventObject): void => {
        if (event.keyCode !== 13) return; // return unless enter-key
        filtersChanged();
      };

      htmlRef().find(`thead`).on(`keydown`, filtersNodeSelector(), handler);
    }
  }

  function filtersChanged(): void {
    updateUrl();
    emit(`filtersChanged`, cloneDeep(filters));
  }

  function setFilter(key: string, value: any): boolean {
    // this function's return value is only used in populateFilters
    if (typeof value == `string` && value.isBlank()) {
      delete filters[key];
      return false;
    }

    // all values are undefined on page load with no params.
    if (value == undefined) {
      return false;
    }

    filters[key] = value;
    return true;
  }

  function updateUrl(): void {
    const baseUrl = `${location.origin}${location.pathname}`;
    const searchParams = new URLSearchParams(window.location.search);

    if (Object.keys(filters).length) {
      const filterParam = serializeFilters(filters);
      searchParams.set(`${options.id}-filter`, filterParam);
    } else {
      searchParams.delete(`${options.id}-filter`);
    }

    const newLocation = `${baseUrl}?${searchParams.toString()}`;
    history.replaceState({url: newLocation}, ``, newLocation);
  }

  function serializeFilters(obj: typeof filters): string {
    return Object.entries(obj)
      .map(([key, value]) => `${key}~${encodeURIComponent(value.split(`~`).join(TILDE_REPLACEMENT))}~`)
      .join(`.`);
  }

  function deserializeFilters(value: string): typeof filters {
    const obj: typeof filters = {};

    const matchesIterator = value.matchAll(/([a-zA-Z0-9_-]+)~([^~]+)~/g);
    const matches = Array.from(matchesIterator);

    matches.forEach(m => {
      obj[m[1]] = decodeURIComponent(m[2]).split(TILDE_REPLACEMENT).join(`~`);
    });

    return obj;
  }

  function populateFilters(): void {
    const columnFilterParams: { [key: string]: string } = {};
    columns.forEach(c => {
      if (c.filterable?.filterName && c.filterable?.default !== ``) {
        columnFilterParams[c.filterable.filterName] = c.filterable?.default!;
      }
    });

    const urlEncodedTableFilters = new URLSearchParams(window.location.search).get(`${options.id}-filter`);
    let urlFilters = {};
    try {
      if (urlEncodedTableFilters) urlFilters = deserializeFilters(urlEncodedTableFilters);
    } catch {
      // Ignore params if they are invalid.
      window.Bugsnag.notify(`Invalid table filters: ${urlEncodedTableFilters}`);
      urlFilters = {};
    }

    const params = Object.assign(columnFilterParams, urlFilters);
    $.each(params, (field, value) => {
      const column = columns
        .filter(column => column.filterable?.enabled)
        .find(column => column.filterable?.filterName === field);

      if (!column) return;
      setFilter(String(field), value);
    });
  }

  // expand stuff
  function setExpandAll(value: boolean): void {
    const td = htmlRef().find(`[js-expand-all]`);
    if (value) {
      td.find(`i`).removeClass(`fa-chevron-right`).addClass(`fa-chevron-down`);

      // NOTE: This event is synchronous. If the event handler is also synchronous,
      // keep in mind that it will postpone expanding the individual rows.
      emit(`allRowsExpanded`);
    } else {
      td.find(`i`).removeClass(`fa-chevron-down`).addClass(`fa-chevron-right`);
    }

    htmlRef().find(`td[js-expand]`).each((_index, row_td) => {
      setExpand($(row_td), value);
    });
  }

  function setExpand(td: JQuery<HTMLElement>, value: boolean): void {
    const previousValue = !!td.find(`i.fa-chevron-down`).length;
    if (value == previousValue) return;

    const tr = td.parents(`tr`);
    const index = tr.data(`index`);

    if (value) {
      td.find(`i`).removeClass(`fa-chevron-right`).addClass(`fa-chevron-down`);

      const item = fullData[Number(index)];
      const content = typeof options.expandedTemplate == `function` ? options.expandedTemplate(item) : options.expandedTemplate;
      const expansionTr = $((
        <tr data-expanded-index={index} class="no-hover">
          <td colspan={1000} dangerouslySetInnerHTML={{__html: content || ``}} />
        </tr>
      ).toString()).insertAfter(tr);

      emit(`rowExpanded`, {item, tr, expansionTr});
    } else {
      td.find(`i`).removeClass(`fa-chevron-down`).addClass(`fa-chevron-right`);
      htmlRef().find(`tr[data-expanded-index="${index}"]`).remove();
    }
  }

  // sorting stuff
  function setSort(e: JQuery.ClickEvent): void {
    if (!e.currentTarget) return;
    const element = $(e.currentTarget);
    const currentOrder = $(element).attr(`data-sort`);

    if (!currentOrder) return;

    // if sort key changes, reset the order
    if (currentOrder !== sort.sidx) {
      sort.sord = `DESC`;
      sort.sidx = currentOrder;
    } else {
      sort.sord = sort.sord === `ASC` ? `DESC` : `ASC`;
    }

    updateSortIcon();
    emit(`sortChanged`);
  }

  function updateSortIcon(): void {
    htmlRef().find(`i.fa-sort-asc, i.fa-sort-desc`).removeClass(`fa-sort-asc fa-sort-desc`);
    htmlRef().find(`[data-sort="${sort.sidx}"]`).children(`i`).addClass(`fa-sort-${sort.sord.toLowerCase()}`);
  }

  // select stuff
  const selectedData: Record<string, Item> = {};

  function selectRowEvent(event: JQuery.ClickEvent): void {
    const index = Number($(event.currentTarget!).attr(`data-index`));
    const selected = $(event.currentTarget!).is(`:checked`);
    selectRow(fullData[index], selected);
  }

  function isRowSelected(item: Item): boolean {
    return !!selectedData[options.selectableUID!(item)];
  }

  function selectRows(items: Item[]): void {
    items.forEach(item => {
      selectRow(item, true);
    });
  }

  function deselectRows(items: Item[]): void {
    items.forEach(item => {
      selectRow(item, false);
    });
  }

  function selectRow(item: Item, isSelected: boolean): void {
    if (isSelected) {
      selectedData[options.selectableUID!(item)] = item;
    } else {
      delete selectedData[options.selectableUID!(item)];
    }
    emit(`rowSelected`, {
      item: item,
      selectableUID: options.selectableUID!(item),
      isSelected,
    });
  }

  function selectAllRowsEvent(event: JQuery.ClickEvent): void {
    const selected = $(event.currentTarget!).is(`:checked`);
    htmlRef()
      .find(`.tt-select-row:not(:disabled)`)
      .prop(`checked`, selected);
    fullData.forEach(item => {
      if (
        typeof options.selectableRows === `function` &&
        options.selectableRows(item) !== true
      ) return;

      selectRow(item, selected);
    });
  }

  function initialRender(): void {
    htmlRef().html(render());
    ready();
    setTimeout(() => emit(`DOMUpdate`, {table: {htmlRef, data: cloneDeep(fullData)}}), 0);
  }

  // render functions
  function render(): string {
    return (
      <>
        <table
          id={options.id}
          class={options.className}
        >
          {renderHeader()}
          <tbody>{renderBody()}</tbody>
          <tfoot>
            {tableOptions.footer ? tableOptions.footer() : ``}
          </tfoot>
        </table>
        <div class="full-page-loader invisible" js-tt-loader>{I18n.t(`loading`)}</div>
        <div js-tt-pagination></div>
      </>
    );
  }

  function renderPagination(): void {
    const pagination = PaginationComponent({
      htmlRef: htmlRef().find(`[js-tt-pagination]`),
      onPageChange: newPage => {
        setPage(newPage);
        emit(`pageChanged`);
      },
      totalPages: numberOfPages,
      defaultPage: getPage(),
    });
    htmlRef().find(`[js-tt-pagination]`).html(options.pagination?.enabled ? pagination.render() : ``);
  }

  function renderHeader(): string {
    return (
      <thead>
        {renderHeaderTitles()}
        {renderHeaderFilters()}
      </thead>
    );
  }

  function renderHeaderTitles(): string {
    const expandable = options.expandable ? <th class="w-info no-print"></th> : ``;
    const rowNumber = options.showRowNumbers ? <th class="w-big-counter no-print"></th> : ``;
    const selectableRow = options.selectableRows ? <th class="w-info no-print"></th> : ``;
    return (
      <tr class="no-print">
        {expandable}
        {rowNumber}
        {selectableRow}
        {columns.filter(c => c.show).map(column => (renderTerrorSortableHeader(column)))}
      </tr>
    );
  }

  function renderHeaderFilters(): string {
    if (
      !columns.some(c => c.show && c.filterable?.enabled) &&
      !options.selectableRows &&
      !options.expandable
    ) return ``;

    const expandable = options.expandable ? <th class="w-info no-print text-c cursor-pointer" js-expand-all><i class="fa fa-chevron-right"></i></th> : ``;
    const rowNumber = options.showRowNumbers ? <th class="w-big-counter no-print"></th> : ``;
    const selectableRow = options.selectableRows ? <th class="w-info no-print"><input type="checkbox" class="tt-select-all-rows" /></th> : ``;
    return (
      <tr>
        {expandable}
        {rowNumber}
        {selectableRow}
        {columns.filter(c => c.show).map(column => renderTerrorFilterableHeader(column, filters[column.filterable?.filterName!]))}
      </tr>
    );
  }

  function paginatedData(): Item[] {
    if (options.dataUrl || !options.rows) return fullData;
    const start = (page - 1) * options.rows;
    const end = start + options.rows;
    return fullData.slice(start, end);
  }

  function renderBody(): string[] {
    if (options.pagination?.enabled) setTimeout(() => renderPagination(), 0);
    return paginatedData().map((item, index) => renderRow(item, index));
  }

  function renderRow(item: Item, index: number) {
    const expandable = options.expandable ? <td class="w-info no-print text-c cursor-pointer" js-expand><i class="fa fa-chevron-right"></i></td> : ``;
    const rowNumber = options.showRowNumbers ? <td class="w-big-counter no-print">{index + 1}</td> : ``;
    let selectableRow = ``;
    let enabled: boolean = false;
    let result: true | Array<string> = [];
    if (options.selectableRows) {
      if (options.selectableRows === true) {
        enabled = true;
      } else {
        result = options.selectableRows(item);
        enabled = result === true;
      }
      const tooltip = result === true ? {} : {'data-tooltip': result.join(`, `)};
      selectableRow = <td class="w-info no-print" {...tooltip}>
        <input
          type="checkbox"
          class="tt-select-row"
          data-index={index}
          checked={isRowSelected(item)}
          disabled={!enabled}
        />
      </td>;
    }
    const className = typeof options.rowClassName === `function` ? options.rowClassName(item) : options.rowClassName;
    return (
      <tr
        data-index={index}
        {...options.rowAttrs ? options.rowAttrs(item) : {}}
        className={className}
      >
        {expandable}
        {rowNumber}
        {selectableRow}
        {columns.filter(c => c.show).map(column => renderTerrorCell(column, item))}
      </tr>
    );
  }

  function reRenderBody(): void {
    if (!htmlRef().find(`tbody`).length) return;

    htmlRef().find(`tbody`)[0].innerHTML = renderBody().join(``);
    setTimeout(() => emit(`DOMUpdate`, {table: {htmlRef, data: cloneDeep(fullData)}}), 0);
  }

  // Data API
  function setRows(data: Array<Item>): void {
    fullData = cloneDeep(data).map(item => { return {...item}; });
    setExpandAll(false);
  }

  function removeRows(filter: (item: Item) => boolean): void {
    fullData = fullData.filter(item => !filter(item));
  }

  function appendRows(data: Array<Item>): void {
    fullData.push(...cloneDeep(data).map(item => { return {...item}; }));
  }

  function setNumberOfPages(pages?: number): void {
    if (pages) {
      numberOfPages = pages;
    } else {
      numberOfPages = Math.ceil(fullData.length / (options.rows || 100));
    }
  }

  function setPage(pageNumber: number): void {
    page = pageNumber;
  }

  function getPage(): number {
    return page;
  }

  return {
    htmlRef,
    loaderRef,
    initialRender,
    reRenderBody,
    setNumberOfPages,
    setPage,
    getPage,
    selectRows,
    deselectRows,
    get sort() { return cloneDeep(sort); },
    get filters() { return cloneDeep(filters); },
    dataAPI: {
      setRows,
      removeRows,
      appendRows,
    },
  };
}
