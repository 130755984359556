export default function(
  element: HTMLElement | null,
  eventName: string,
  innerSelector: string,
  callback: Function,
) {
  if (!element) return;

  const handler = (event: Event) => {
    // loop parent nodes from the target to the delegation node
    for (
      let target = event.target as HTMLElement;
      target && target != element;
      target = target.parentNode as HTMLElement
    ) {
      if (target.matches(innerSelector)) {
        callback(event);
        break;
      }
    }
  };

  element.addEventListener(eventName, handler, false);

  return handler;
}
