import h from 'stringjsx';
import I18n from '@/modules/i18n';

const PaginationComponent = (
  {
    htmlRef,
    onPageChange,
    totalPages,
    defaultPage = 1,
  }:
  {
    htmlRef: JQuery<HTMLElement>,
    onPageChange: (page: number) => void,
    totalPages: number,
    defaultPage: number,
  }
) => {
  let currentPage: number = defaultPage;
  htmlRef.off(`click`);
  htmlRef.on(`click`, `a.previous_page`, () => setPage(currentPage - 1));
  htmlRef.on(`click`, `a.next_page`, () => setPage(currentPage + 1));
  htmlRef.on(`click`, `a.page`, event => setPage(parseInt(event.currentTarget.textContent || `1`)));

  const setPage = (page: number): void => {
    currentPage = page;
    onPageChange(currentPage);
    reRender();
  };

  const reRender = () => {
    htmlRef.html(render());
  };

  const render = () => {
    let gapExists = false;
    const pages = [];

    for (let page = 1; page <= totalPages; page++) {
      if (
        [1, 2, totalPages - 1, totalPages].includes(page) || // first or last two
          Math.abs(page - currentPage) <= 4 // up to 4 away from current
      ) {
        pages.push(page);
        gapExists = false;
      } else if (!gapExists) {
        pages.push(`gap`);
        gapExists = true;
      }
    }

    const pagesHtml = pages.map(page => {
      if (page === `gap`) return <span className="gap">{I18n.t(`will_paginate.page_gap`)}</span>;
      if (page === currentPage) return <em className="current">{page}</em>;
      return <a className="page" data-page={page}>{page}</a>;
    });

    const prevHtml = currentPage === 1
      ? <span className="previous_page disabled">{I18n.t(`will_paginate.previous_label`)}</span>
      : <a className="previous_page" rel="prev">{I18n.t(`will_paginate.previous_label`)}</a>;

    const nextHtml = currentPage === totalPages
      ? <span className="next_page disabled" dangerouslySetInnerHTML={{__html: I18n.t(`will_paginate.next_label`)}}></span>
      : <a className="next_page" rel="prev">{I18n.t(`will_paginate.next_label`)}</a>;

    return (
      <div className="pagination">
        {prevHtml}
        {pagesHtml}
        {nextHtml}
      </div>
    );
  };

  return {
    render,
    reRender,
  };
};

export default PaginationComponent;
