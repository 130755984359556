import genericErrorHandler from '@/modules/request/error_handlers';
import {invisibleOhNo} from '@/modules/oh_no';

export function monkeyPatchXHRWithCurrentUnitHeader() {
  const CurrentUnitId = document.querySelector(`meta[name="CURRENT_UNIT_ID"]`)?.content;
  // It is important that we send CURRENT-UNIT-ID to the backend and it is different on browser tab level.
  const XMLHttpRequest_prototype_send = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function() {
    // this["BS~~U"] gets us the url. The regex will try to find '://', this is to catch remote requests
    // over all protocols like for example: https://, wss:// or ftp://, if the url does not have a full address
    // it will be a same-site request.
    const requestIsSameSite = !this[`BS~~U`]?.match(/.*:\/\/.*/);
    if (requestIsSameSite && CurrentUnitId) this.setRequestHeader(`CURRENT-UNIT-ID`, CurrentUnitId);
    return XMLHttpRequest_prototype_send.apply(this, arguments);
  };
}

export function configureAjax() {
  // If the backend does not support this format(respond_to block without this format) we will get a 406 instead of the response rendered in the controller.
  // When we do render json: ... it will respond with json to a request where you say you only accept html response.
  // when ajax sees that the response's content_type is application/json it will try to parse the response as JSON.
  // https://api.jquery.com/jQuery.ajax/
  $.ajaxSetup({
    // default to json format, when we have others we should specify it, via dataType, and possibly additional headers.
    dataType: `json`,
    // here we are overriding ajax's converter for json formats, dirty, but hey: IT WORKS!
    // We are now allowing the body to be empty or invalid JSON, which wasn't allowed before.
    converters: {
      'text json': function(result) {
        try {
          return result ? JSON.parse(result) : null;
        } catch (e) {
          Bugsnag?.leaveBreadcrumb(`requestResult type unknown`, {requestResult: result});
          invisibleOhNo(
            `We expected a json response but got something else, update the dataType attr on the ajax request!`,
            {groupingHash: `ajax-wrong-data-type`},
          );
          return result;
        }
      },
    },
  });

  $(document).ajaxError(errorHandler);
}

function errorHandler(event, jqXHR, ajaxOptions, errorText) {
  genericErrorHandler(
    {
      type: ajaxOptions.type,
      url: ajaxOptions.url,
      useBugsnag: ajaxOptions.useBugsnag,
      unhandledError: ajaxOptions.unhandledError,
      error: ajaxOptions.error,
      errorMessageHandler: ajaxOptions.errorMessageHandler
    },
    {
      status: jqXHR.status,
      statusText: errorText,
      responseJSON: jqXHR.responseJSON,
      responseRaw: jqXHR.responseText
    }
  );
}
