export default function buildAlertifyChangedUnit() {
  if (!alertify.ChangedUnit) {
    alertify.dialog(`ChangedUnit`, function factory() {
      return {
        main: function(units) {
          this.units = units;
        },
        setup: function() {
          return {
            buttons: [],
            options: {
              title: I18n.t(`users.changed_unit_title`),
              movable: false,
              resizable: false,
              closable: false,
              maximizable: false,
              modal: true,
            }
          };
        },
        prepare: function() {
          this.setContent(
            `
            <div class="text-c">
              <h3 class="text-error">${I18n.t(`users.change_unit_explanation`)}</h3>
              <h4>${I18n.t(`users.change_unit_todo`)}</h4>
              <br>
              <div class="d-inline-block m-b-4">
                <button class="buttons-standard" onclick="changedUnitCallbacks.onChange(${this.units.old_unit_id});">
                  <h4>
                    ${I18n.t(`users.change_unit_back`, {unit_name: this.units.old_unit_name})}
                  </h4>
                </button>
              </div>
              <div class="d-inline-block">
                <button class="buttons-standard" onclick="changedUnitCallbacks.onKeep();">
                  <h4>
                    ${I18n.t(`users.keep_unit`, {unit_name: this.units.new_unit_name})}
                  </h4>
                </button>
              </div>
            </div>
            `
          );
        },
      };
    }, true);
  }
}
