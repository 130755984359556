import {axios} from "@/modules/request/axios_configuration";
import {AxiosPromise} from "axios";

function getAxiosHtml(type: string, id: number): AxiosPromise<string> {
  return axios({
    method: `GET`,
    url: `/action_log/display/${type}/${id}}`,
  });
}

export {getAxiosHtml as getActionLog};
