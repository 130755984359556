import axios, {AxiosError, InternalAxiosRequestConfig, AxiosResponse} from 'axios';
import qs from 'qs';
import genericErrorHandler from '@/modules/request/error_handlers';

// If the request does not have this header the rails server does not manage to
// handle the request in MimeNegotiation, so `respond_to` blocks doesnt work.rb
// If you want to look into it in rails source code, you can start here: https://github.com/rails/rails/blob/aa5a4a75662773a92dd9f3b9ad59b65c02e8e5c9/actionpack/lib/action_dispatch/http/mime_negotiation.rb#L79
// Solution found here: https://stackoverflow.com/questions/43375780/how-to-make-axios-make-call-for-json-response-with-rails
axios.defaults.headers.common[`X-Requested-With`] = `XMLHttpRequest`;

axios.interceptors.request.use(addCSRFToken);
axios.interceptors.request.use(addQSParamSerializer);
axios.interceptors.response.use(successHandler, errorHandler);

function addCSRFToken(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  const param = document.querySelector(`meta[name="csrf-param"]`);
  const token = document.querySelector(`meta[name="csrf-token"]`)?.getAttribute(`content`);

  if (config.headers && token) {
    config.headers[`X-CSRF-Token`] = token;
  }

  return config;
}

function addQSParamSerializer(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  config.paramsSerializer = {
    serialize: params => qs.stringify(params, {arrayFormat: `brackets`, encode: false}),
  };

  return config;
}

function successHandler(response: AxiosResponse) {
  if (typeof response.config.success == `function`) {
    response.config.success(response);
  }
  return response;
}

function errorHandler(error: AxiosError) {
  genericErrorHandler(
    {
      type: error.config!.method!,
      url: error.config!.url!,
      useBugsnag: error.config!.useBugsnag,
      unhandledError: error.config!.unhandledError,
      error: error.config!.error,
      errorMessageHandler: error.config!.errorMessageHandler
    },
    error.response && {
      status: error.response.status,
      statusText: error.response.statusText,
      responseJSON: error.response.headers[`content-type`].startsWith(`application/json`) ? error.response.data : undefined,
      responseRaw: error.response.data,
    }
  );

  // To keep the original axios behavior, we should return a failed Promise always.
  // But we like to use it the way we have used jQuery.ajax with setting
  // error callback. If you pass callback we'll handle it as callback, if not we
  // handle it as a rejected promise.
  if (typeof error.config!.error == `function`) {
    error.config!.error(error);
    return;
  } else {
    return Promise.reject(error);
  }
}

export {axios};

// eslint-disable-next-line quotes
declare module 'axios' {
  interface AxiosRequestConfig<D = any> {
    useBugsnag?: boolean;
    success?: (response: AxiosResponse) => void;
    error?: (error: AxiosError) => void;
    unhandledError?: (error: AxiosError) => void;
    errorMessageHandler?: (type: string | undefined, errorMessage: string) => void;
  }
}
