export default function<Item>(
  array: Item[],
  sortByValue: (item: Item) => string,
  term: string,
): Item[] {
  term = term.toLowerCase();

  const sorted = [...array];

  sorted.sort((a, b) => {
    const a_term = sortByValue(a);
    const b_term = sortByValue(b);
    const index_a = a_term.toLowerCase().indexOf(term);
    const index_b = b_term.toLowerCase().indexOf(term);

    if (index_a == -1 && index_b == -1) return 0;
    if (index_a == -1) return 1;
    if (index_b == -1) return -1;

    if (index_a > index_b) return 1;
    if (index_a < index_b) return -1;

    return a_term.toLowerCase().localeCompare(b_term.toLowerCase());
  });

  return sorted;
}
