import I18n from '@/modules/i18n';

export function amount(text: string | number): string {
  return I18n.l(`number`, text);
}

export function unamount(amountString: string | number | null | undefined): number {
  let amount;

  if (!amountString) return 0.0;
  if (typeof amountString === `number`) return amountString;

  switch (I18n.locale) {
    case `en`:
      amount = amountString.replace(/,/g, ``);
      break;

    case `nb`:
      amount = amountString.replace(/,/g, `.`).replace(/ /g, ``);
      break;

    default:
      amount = amountString.replace(/,/g, `.`).replace(/ /g, ``);
      break;
  }

  amount = amount.replace(/\.+/g, `.`);
  return parseFloat(amount) || 0.0;
}

export default {
  amount,
  unamount,
};
