/**
 *  You can (should) pass a string when working with really big numbers.
 */
export default (divident: string | number, divisor: number): number => {
  divident = divident.toString();

  let remainder = 0;
  for (let i = 0; i < divident.length; ++i) {
    remainder = (remainder * 10 + parseInt(divident.charAt(i))) % divisor;
  }
  return remainder;
};
