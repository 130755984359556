import buildAlertifyPleaseReload from '@/modules/request/alertify_please_reload';
import buildAlertifyChangedUnit from '@/modules/request/alertify_changed_unit';
import buildAlertifyLoggedOut from '@/modules/request/alertify_logged_out';
import escapeHtml from '@/functions/processors/escapeHtml';
import I18n from '@/modules/i18n';
import {
  alertifySomethingWentWrongReported,
  reportError,
} from '@/modules/oh_no';

interface Options {
  report?: boolean;
  groupingHash?: string;
}

export interface Request {
  type: string;
  url: string;
  useBugsnag?: boolean;
  error?: (error: any) => void;
  unhandledError?: (error: any) => void;
  errorMessageHandler?: (type: string | undefined, errorMessage: string) => void;
}

export interface Response {
  status: number;
  statusText: string;
  responseJSON?: any;
  responseRaw?: any;
}

export function loadRequirements(): void {
  buildAlertifyPleaseReload();
  buildAlertifyChangedUnit();
  buildAlertifyLoggedOut();
}

function coalesceTimeout(timeout?: number): number | undefined {
  if (timeout != null) {
    return timeout;
  } else {
    return alertify.get(`notifier`, `delay`);
  }
}

function reportRequestError(
  request: Request, response: Response, errorText: string,
) {
  let message = ``;
  message += `${request.type} ${request.url}`;
  message += ` - `;
  message += `${response.status} ${response.statusText}`;
  message += ` - `;
  message += `ERROR: "${errorText}"`;

  reportError(message, {report: request.useBugsnag, logReporting: false});
}

function handleUnknownError(request: Request, errorText: string): void {
  if (errorText && errorText !== `error` && errorText !== `Error`) {
    reportError(
      `${request.type} ${request.url} - NETWORK ERROR: ${errorText}`,
      {report: request.useBugsnag, logReporting: false},
    );
  }
}

function handleUnauthorized(): void {
  alertify.LoggedOut!(I18n.t(`users.logged_out_text`)).closeOthers();
}

function handleForbidden(): void {
  alertify.error(I18n.t(`insufficient_permissions`), 15);
}

function handleDesyncError(response: Response): void {
  const msg = response.responseJSON.message;

  alertify.PleaseReload!(msg);
}

// 469 custom http status contains this type of json response
interface CustomResponseErrorMessage {
  type?: string;
  message: string;
  timeout?: number
}

function handleMessageError(response: Response, handler: Request[`errorMessageHandler`]): void {
  let messages: CustomResponseErrorMessage[];
  if (response.responseJSON) {
    messages = response.responseJSON.errors;
  } else {
    messages = JSON.parse(response.responseRaw).errors;
  }

  if (typeof handler === `function`) {
    messages.forEach(entry => {
      handler(entry.type, entry.message);
    });
    return;
  }

  messages.forEach(entry => {
    entry.message = escapeHtml(entry.message);
  });

  messages.forEach(entry => {
    switch (entry.type) {
      case `warning`:
        alertify.warning(entry.message, coalesceTimeout(entry.timeout));
        break;
      case `basic`:
      case `info`:
      case `notice`:
      case `message`:
        alertify.message(entry.message, coalesceTimeout(entry.timeout));
        break;
      case `success`:
        alertify.success(entry.message, coalesceTimeout(entry.timeout));
        break;
    }
  });

  const errorMessages = messages
    .filter(entry => !entry.type || entry.type == `error`);

  if (errorMessages.length > 0) {
    alertify
      .alert(`<ul>${errorMessages.map(entry =>`<li>${entry.message}</li>`).join(``)}</ul>`)
      .setHeader(I18n.t(`error`));
  }
}

function handleChangedUnit(response: Response): void {
  alertify.ChangedUnit!({...response.responseJSON,
    old_unit_name: window.current_unit_name,
    old_unit_id: window.current_unit_id,
  }).closeOthers();
}

function handleUnhandledError(request: Request, response: Response, errorText: string): void {
  if (typeof request.unhandledError === `function`) {
    request.unhandledError(response);
  }

  if (typeof request.error !== `function` && typeof request.unhandledError !== `function`) {
    alertifySomethingWentWrongReported();
  }

  reportRequestError(request, response, errorText);
}

export default function genericErrorHandler(request: Request, response: Response | undefined): void {
  if (!response) {
    // This happens when request is cancelled. Handle outside of here.
    return;
  }

  // see http_status_codes.rb for custom status codes and explanations
  switch (response.status) {
    case 0:
      // 0 is things like network errors, impossible to really deal with in js
      handleUnknownError(request, response.statusText);
      break;
    // case 200..299
    //  You would think that ajaxError couldn't happen if you get a 200 range
    //  response, but you can. ajaxError gets triggered when JQuery ajax
    //  experienced an error doing the request or the response has status code
    //  4-500 range.
    //  Example:
    //    ajaxOption dataType is set to JSON which mean the response should
    //      be json. JQuery will try to parse the response with JSON.parse. If
    //      the response was code 201 without a body, JSON.parse would raise
    //      and JQuery trigger ajaxError.
    case 401:
      handleUnauthorized();
      break;
    case 403:
      handleForbidden();
      break;
    case 406:
      alertifySomethingWentWrongReported();
      reportRequestError(
        request, response,
        `We requested a format the server does not support`,
      );
      break;
    case 468: // 'Desync likely'
      handleDesyncError(response);
      break;
    case 469: // 'Plaintext message for user'
      handleMessageError(response, request.errorMessageHandler);
      break;
    case 470: // 'Wrong unit'
      if (response.responseJSON) handleChangedUnit(response);
      break;
    default:
      // for when you haven't handled the failure in any other way
      handleUnhandledError(request, response, response.statusText);
  }
}
